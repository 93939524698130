
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { Form as Validation } from 'vee-validate'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    FlowStep,
    Validation,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:email', 'step-change'],
  setup(props, { emit }) {
    const { openModal } = useModals()
    const email = ref('')
    const form = ref()
    const isLoading = ref(false)
    const currentInboxType = ref('existing')

    const validateForm = () => {
      form.value.$el.dispatchEvent(new Event('submit', { cancelable: true }))
    }

    const handleClick = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false
        emit('step-change', props.step + 1)
        emit('update:email', email)
      }, 1500)
    }

    const buttons = [
      {
        icon: 'tmi-email-send-direct',
        name: 'existing',
        title: 'Connect an existing inbox',
        text: 'Forward emails from your current inbox. Works with all email providers.',
      },
      {
        image: require('@/assets/images/logo-small.svg'),
        name: 'new',
        title: 'Create a new Textmagic inbox',
        text: 'Get a new email inbox. Quick setup.  e.g. support@company.textmagic.com ',
      },
    ]

    return {
      email,
      currentInboxType,
      form,
      buttons,
      isLoading,
      openModal,
      handleClick,
      validateForm,
    }
  },
})
