
import { defineComponent, reactive, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import InboxPreview from '@/components/pages/tickets/InboxPreview.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    FlowStep,
    InboxPreview,
  },
  setup() {
    const state = reactive({
      name: 'Textmagic Support',
      user: { fullName: 'Unassigned' },
    })
    const addAgent = ref(false)

    return {
      state,
      assigneeOptions,
      addAgent,
    }
  },
})
