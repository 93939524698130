
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import EmailStep from '@/components/pages/tickets/settings/inboxes/flow/EmailStep.vue'
import EmailForwardingStep from '@/components/pages/tickets/settings/inboxes/flow/EmailForwardingStep.vue'
import SettingsStep from '@/components/pages/tickets/settings/inboxes/flow/SettingsStep.vue'

export default defineComponent({
  components: {
    PageContent,
    DetailsHero,
    FlowStepList,
    EmailStep,
    EmailForwardingStep,
    SettingsStep,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Inboxes', url: { name: 'base.tickets.settings.inboxes' } },
      { label: 'Connect new inbox' },
    ]
    const currentStep = ref(1)
    const email = ref('')

    return {
      breadcrumbs,
      currentStep,
      email,
    }
  },
})
