import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_email_step = _resolveComponent("email-step")!
  const _component_email_forwarding_step = _resolveComponent("email-forwarding-step")!
  const _component_settings_step = _resolveComponent("settings-step")!
  const _component_flow_step_list = _resolveComponent("flow-step-list")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        icon: "tmi-email-add",
        title: "Connect new inbox",
        class: "mb-4"
      }),
      _createVNode(_component_flow_step_list, {
        "onUpdate:currentStep": _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentStep = $event))
      }, {
        default: _withCtx(({ currentStep, onStepChange }) => [
          _createVNode(_component_email_step, {
            step: 1,
            "current-step": currentStep,
            "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => (_ctx.email = $event)),
            onStepChange: onStepChange
          }, null, 8, ["current-step", "onStepChange"]),
          _createVNode(_component_email_forwarding_step, {
            step: 2,
            "current-step": currentStep,
            email: _ctx.email,
            onStepChange: onStepChange
          }, null, 8, ["current-step", "email", "onStepChange"]),
          _createVNode(_component_settings_step, {
            step: 3,
            email: _ctx.email,
            "current-step": currentStep,
            onStepChange: onStepChange
          }, null, 8, ["email", "current-step", "onStepChange"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}