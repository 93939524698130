
import { defineComponent, ref, watch, computed } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { copyToClipboard } from '@/services/utils'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: {
    TmStylizedText,
    TmFormLine,
    TmAlert,
    TmTooltip,
    TmButton,
    FlowStep,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
    },
  },
  emits: ['step-change'],
  setup(props, { emit }) {
    const inboxList = [
      {
        label: 'Gmail',
        image: require('@/assets/images/logos/gmail.svg'),
      },
      {
        label: 'Office 365',
        image: require('@/assets/images/logos/office365.png'),
      },
      {
        label: 'Apple Mail',
        image: require('@/assets/images/logos/apple.png'),
      },
      {
        label: 'Outlook',
        image: require('@/assets/images/logos/outlook.png'),
      },
      {
        label: 'Yahoo!mail',
        image: require('@/assets/images/logos/yahoo.png'),
      },
    ]
    const clickCount = ref(0)
    const isLoading = ref(false)
    const isConfigured = ref(true)
    const runTest = () => {
      isLoading.value = true
      isConfigured.value = true
      setTimeout(() => {
        clickCount.value++
        isLoading.value = false
      }, 2500)
    }
    const customEmail = ref('gx4f8jkwdc638@company.textmagic.com')
    const finishedText = computed(() => {
      if (isConfigured.value) {
        return [
          'Forwarding has been successfully configured for ',
          {
            text: props.email,
            style: 'semi-bold',
          },
        ]
      }
      return [
        'Forwarding ',
        {
          text: 'has not been',
          style: 'semi-bold',
        },
        ' configured.',
      ]
    })

    const copiedBuffer = ref('Copy')
    const copyFunc = (text: string) => {
      copiedBuffer.value = 'Copied'
      copyToClipboard(text)
    }
    const restoreCopy = () => setTimeout(() => {
      copiedBuffer.value = 'Copy'
    }, 200)

    watch(clickCount, (value) => {
      if (value > 2) emit('step-change', props.step + 1)
    })

    const handleFinishLater = () => {
      isConfigured.value = false
      emit('step-change', props.step + 1)
    }

    return {
      handleFinishLater,
      inboxList,
      clickCount,
      finishedText,
      isLoading,
      copiedBuffer,
      runTest,
      copyFunc,
      customEmail,
      restoreCopy,
    }
  },
})
