
import { defineComponent } from 'vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmEllipsis,
    TmAvatar,
  },
  props: {
    name: {
      type: String,
    },
    addAgent: {
      type: Boolean,
    },
    email: {
      type: String,
      default: 'support@textmagic.com',
    },
  },
})
